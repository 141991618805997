<template>
  <div class="page">
      
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import storage from 'store'

import { Toast } from 'vant'



function GetQueryString(name, url) {
  if (name && url) {
    var index = url.lastIndexOf("?");
    url = url.substring(index + 1, url.length)
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = url.match(reg); //获取url中"?"符后的字符串并正则匹配
    var context = "";
    if (r != null)
        context = decodeURIComponent(r[2]);
    reg = null;
    r = null;
    return context == null || context == "" || context == "undefined" ? "" : context;
  }
}

export default {
    data() {
      return {
         cid: '', 
         check_code: '',
         t_url: ''
      }
    },
    created() {
      Toast.loading({
        message: '登录中...',
        forbidClick: true,
        duration: 0
      }); 
      this.t_url = this.$route.query.t_url || ''
      this.cid = storage.get('cid') || GetQueryString('cid', this.$route.query.t_url)
      this.check_code = storage.get('check_code') || GetQueryString('check_code', this.$route.query.t_url)
      // alert(GetQueryString('check_code', this.$route.query.t_url))
      this.getOpenUserInfo()
    },
    methods: {
      ...mapActions(['Login']),
      getOpenUserInfo () {
        let that = this;
        that.Login({
          cid: that.cid,
          check_code: that.check_code 
        }).then((res) => {
          if (!res.data.token) {
            let redirectUrl = '/auth/officialAccount?cid='+that.cid+'&check_code=' + that.check_code + '&module=officialAccount&target=' + encodeURIComponent(window.location.href);
            that.$redirectAuth(redirectUrl);
          }
          Toast.clear()
          this.$router.push(this.t_url)
        }).catch(res => {
          let redirectUrl = '/auth/officialAccount?cid='+that.cid+'&check_code=' + that.check_code + '&module=officialAccount&target=' + encodeURIComponent(window.location.href);
          that.$redirectAuth(redirectUrl);
        })
      },
    }
}
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
}
</style>
